/*eslint no-case-declarations: "off"*/

import orm from '../models/orm'
import {
  REQUEST_COMBINED,
  RECEIVE_COMBINED
} from '../actions/fetch_combined'

import {
  RECEIVE_COMPOSITION_DETAIL, REQUEST_COMPOSITION_DETAIL,
  RECEIVE_PERFORMANCE_VIDEO_INFO, REQUEST_PERFORMANCE_VIDEO, RECEIVE_ALL_PROGRAM_VIDEO
} from '../actions/fetch_composition'

import {
  RECEIVE_PROGRAM_DETAIL
} from '../actions/fetch_program'

import {
  RECEIVE_PERFORMANCE_AUDIO_INFO,
  FAILED_FETCHING_STREAM_INFO
} from '../actions/player'

import {
  RECEIVE_OBJECT_STREAM_LINKS
} from '../actions/misc_streams'

import {
  CHECK_LIVE_STREAMS,
  FETCHING_MEDIA_TYPE,
  RECEIVE_MEDIA_TYPE,
  MEDIA_TYPE_LIVE_STREAM,
  MEDIA_TYPE_MEDIA_FEATURE,
  MEDIA_TYPE_CURATED_COLLECTION,
  MEDIA_TYPE_HOME,
} from '../actions/media_type_fetch'


const importCollections = (collections_json, session) => {
  collections_json.forEach( (obj) => {
    const collection = session.CuratedCollection.create(obj)

    obj.active_items.forEach( (item) => {
      const performance = updatePerformance(item.composition_performance, session)

      session.CuratedItem.create({
        curated_collection: collection.id,
        performance: performance.id,
        note: item.item_note_text,
        sequence: item.sequence
      })
    })
  })
}

const updatePerformance = (perf_json, session) => {
  const perf = session.Performance.findById(perf_json.id)
  if(perf) {
    perf.featuredartistSet.delete()
    // Existing performances already have composition data
    delete perf_json.composition
    perf.update(perf_json)
    for(var fa_json of perf_json.featured_artists) {
      session.FeaturedArtist.create({
        performance: perf.id,
        artist: fa_json.person,
        role: fa_json.role,
        sequence: fa_json.sequence
      })
    }
    return perf
  }
  else {
    // These are cases where a Program has a non-playable performance;
    // have to massage the data
    const composer = session.Person.findByIdentifier(perf_json.composition.composer_identifier)
    if(composer) {
      perf_json.composition.composer = composer.id
    }
    if(perf_json.composition) {
      perf_json.composition.forDisplay = false
      let new_composition = session.Composition.create(perf_json.composition)
      perf_json.composition = new_composition.id
    }
    session.Performance.create(perf_json)
  }
}

const updateComposition = (composition, json, session) => {
  for(var perf_json of json.performances_with_recordings) {
    updatePerformance(perf_json, session)
  }

  json.arranger = json.arranger_id

  delete json.composer // the composer is already there
  composition.update(json)
  if(composition.performances.filter({unlisted: false}).count() == 0) {
    composition.unlisted = true
  }
}

const updateProgram = (program, json, session) => {
  let perf_json
  for(var i = 0; i < json.composition_performances.length; i++) {
    perf_json = json.composition_performances[i]
    perf_json.program = program.id
    perf_json.position = i
    updatePerformance(perf_json, session)
  }
  program.update(json)

  // temporary check, node may not be there with old API
  if(json.related_media_features) {
    for(var related_json of json.related_media_features) {
      const mf = session.MediaFeature.create(related_json.media_feature)
      session.RelatedMediaFeature.create({
        media_feature: mf.id,
        program: program.id,
        description: related_json.description
      })
    }
  }

}

const data = (state = {
  isFetchingCombined: false,
  isCompleteCombined: false,
  detailForUpdate: [],
  collectionDetailForUpdate: [],
  programsDetailForUpdate: [],
  curatedCollectionsForUpdate: [],
  fetchedMediaType: {},
  isFetchingMediaType: {},
  liveStreamsFetched: false,
  orm: orm.getEmptyState()
}, action) => {
  const session = orm.session(state.orm)
  const { Composition, Person, Performance, Program, FeaturedArtist, LiveStream, MediaFeature, HomeContent } = session
  let update_hash // Used in various actions

  switch (action.type) {
  case RECEIVE_COMBINED:
    action.data.people.forEach( (obj) => {
      obj.sort_name = `${obj.last_name}, ${obj.first_name}`
      Person.create(obj)
    })
    action.data.programs.forEach( (obj) => {
      Program.create(obj)
    })
    action.data.compositions.forEach( (composition_json) => {
      composition_json.composer = composition_json.composer_id
      composition_json.unformatted_title = composition_json.title.replace(/\*/g, '')

      let comp = Composition.create(composition_json)
      for(var perf of composition_json.performances_with_recordings) {
        Performance.create(Object.assign({}, perf, {
          composition: comp
        }))

        for(var artist_id of perf.featured_artist_ids) {
          FeaturedArtist.create({
            performance: perf.id,
            artist: artist_id
          })
        }
      }
      if(comp.performances.filter({unlisted: false}).count() == 0) {
        comp.unlisted = true
      }
    })
    // Any comp details fetched before entire list is available
    // were saved in state.detailForUpdate
    for(var detailPayload of state.detailForUpdate) {
      let comp = Composition.findById(detailPayload.id)
      detailPayload.hasDetail = true
      updateComposition(comp, detailPayload, session)
    }
    for(var programPayload of state.programsDetailForUpdate) {
      let program = Program.findById(programPayload.id)
      updateProgram(program, programPayload, session)
    }

    update_hash = {
      isFetchingCombined: false,
      isCompleteCombined: true,
      lastUpdatedCombined: action.receivedAt,
      detailForUpdate: [],
      programsDetailForUpdate: [],
      curatedCollectionsForUpdate: []
    }

    if(state.curatedCollectionsForUpdate.length > 0) {
      importCollections(state.curatedCollectionsForUpdate, session)
      // Need to tell state collections are done fetching
      update_hash.curatedCollectionsForUpdate = null

      update_hash.isFetchingMediaType = Object.assign({}, session.state.isFetchingMediaType, {
        [MEDIA_TYPE_CURATED_COLLECTION]: false
      })
      update_hash.fetchedMediaType = Object.assign({}, state.fetchedMediaType, {
        [MEDIA_TYPE_CURATED_COLLECTION]: true
      })
    }
    // Have to wait to assign state to orm, otherwise changes are not picked up
    update_hash.orm = session.state
    return Object.assign({}, state, update_hash)
  case REQUEST_COMPOSITION_DETAIL:
    // a stub atm
    return state
  case REQUEST_PERFORMANCE_VIDEO:
    const fetching_video = Performance.withId(action.performance_id)
    fetching_video.update({fetched_video: 'in_progress'})

    return Object.assign({}, state, {
      orm: session.state
    })
  case RECEIVE_COMPOSITION_DETAIL:
    let comp = Composition.findById(action.composition.id)
    if(comp) {
      action.composition.hasDetail = true
      updateComposition(comp, action.composition, session)

      return Object.assign({}, state, {
        orm: session.state
      })
    } else {
      // save detail payload for updating after list is received
      return Object.assign({}, state, {
        detailForUpdate: [ ...state.detailForUpdate, action.composition]
      })
    }
  case RECEIVE_PROGRAM_DETAIL:
    let program = Program.findById(action.json.id)
    action.json.hasDetail = true

    if(program && state.isCompleteCombined) {
      updateProgram(program, action.json, session)
      return Object.assign({}, state, {
        orm: session.state
      })
    } else {
      // save detail payload for updating after list is received
      return Object.assign({}, state, {
        programsDetailForUpdate: [ ...state.programsDetailForUpdate, action.json]
      })
    }
  case RECEIVE_ALL_PROGRAM_VIDEO:
    let video_program = Program.findById(action.program_id)
    video_program.update({fetched_all_video: true})
    return Object.assign({}, state, {
      orm: session.state
    })
  case RECEIVE_PERFORMANCE_AUDIO_INFO:
    // Note: We're updating both the passed performance & the one attached to the session; the passed performance may not be assigned
    update_hash = {
      fetched_audio: 'success',
      stream_links: action.json.stream_links
    }
    if(action.performance) {
      action.performance.update(update_hash)
    }
    // In case performance in queue was removed from API
    let targetPerformance = Performance.findById(action.performance_id)
    if(targetPerformance) {
      targetPerformance.update(update_hash)
    }
    return Object.assign({}, state, {
      orm: session.state
    })
  // stream_type failure_type parent_record
  case FAILED_FETCHING_STREAM_INFO:
    update_hash = {
      [`fetched_${action.stream_type}`]: action.failure_type,
      ['stream_error_message']: action.error_message
    }
    if(action.parent_record) {
      updateRecordFromSession(session, action.parent_record, update_hash)
      // Updating both the passed record & the saved one,
      // not sure that's necessary
      action.parent_record.update(update_hash)
    }
    return Object.assign({}, state, {
      orm: session.state
    })

  case RECEIVE_PERFORMANCE_VIDEO_INFO:
    // TODO: have to update the passed performance & the one attached to the session; the passed performance may not be assigned
    update_hash = {
      fetched_video: 'success',
      video_stream_links: action.json.stream_links
    }
    if(action.performance) {
      action.performance.update(update_hash)
    }
    Performance.withId(action.performance_id).update(update_hash)

    return Object.assign({}, state, {
      orm: session.state
    })
  // Generalized tracking
  case FETCHING_MEDIA_TYPE:
    const fetching = Object.assign({}, state.isFetchingMediaType, {
      [action.kind]: true
    })
    return Object.assign({}, state, {
      isFetchingMediaType: fetching
    })

  case RECEIVE_MEDIA_TYPE:
    const fetched = Object.assign({}, state.fetchedMediaType, {
      [action.kind]: true
    })
    const stopFetching = Object.assign({}, state.isFetchingMediaType, {
      [action.kind]: false
    })
    const data = action.data.results || action.data
    switch (action.kind) {
      case MEDIA_TYPE_MEDIA_FEATURE:
        data.forEach( (obj) => {
          MediaFeature.createDecorated(obj)
        })
        break;
      case MEDIA_TYPE_CURATED_COLLECTION:
        if(state.isCompleteCombined) {
          importCollections(data, session)
        } else {
          // save detail payload for updating after list is received
          return Object.assign({}, state, {
            curatedCollectionsForUpdate: data
          })
        }
        break;
      case MEDIA_TYPE_HOME:
        data.forEach( (obj) => {
          HomeContent.create(obj)
        })
        break;
      case MEDIA_TYPE_LIVE_STREAM:
        data.forEach( (obj) => {
          // Debugging:
          // obj.start_date_time = "2017-06-10T22:35:00Z"
          // obj.open_date_time = "2017-06-10T22:33:00Z"
          // obj.status = "I"
          let stream = LiveStream.create(obj)
          stream.checkLiveTime()
        })
        break;
    }
    return Object.assign({}, state, {
      orm: session.state,
      isFetchingMediaType: stopFetching,
      fetchedMediaType: fetched
    })
  case CHECK_LIVE_STREAMS:
    LiveStream.all().toModelArray().forEach( (stream) => stream.checkLiveTime())
    return Object.assign({}, state, {
      orm: session.state,
    })
  case RECEIVE_OBJECT_STREAM_LINKS:
    update_hash = {
      fetched_streaming_links: 'success',
      stream_links: action.json.stream_links
    }
    updateRecordFromSession(session, action.parent_record, update_hash)
    return Object.assign({}, state, {
      orm: session.state
    })
  case REQUEST_COMBINED:
    return Object.assign({}, state, {
      isFetchingCombined: true
    })
  default:
    return state
  }
}

const updateRecordFromSession = (session, record, update_hash) => {
  session[record.getClass().modelName].withId(record.id).update(update_hash)
}

export default data
