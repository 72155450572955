/* eslint react/prop-types: "off"*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from '../form/InputField'
import SessionHeader from './SessionHeader'
// import AccountBenefits from './AccountBenefits'
import { required, email } from '../form/validations'
// import FacebookLogin from 'react-facebook-login'
// import facebookIcon from '../../../images/icons/facebook.svg'
import appleIcon from '../../../images/icons/apple_logo.svg'
import SvgIcon from '../SvgIcon'

import OrLine from './OrLine'
import {setTitle, initAppleID} from '../../util'


class SignIn extends Component {
  constructor(props) {
    super()
    this.responseFacebook = this.responseFacebook.bind(this)
    this.onAppleIdSuccess = this.appleIdSuccess.bind(this)
    this.onAppleIdFailure = this.appleIdFailure.bind(this)
    this.onNativeAppleLoginClick = this.nativeAppleLogin.bind(this)
  }
  componentDidMount() {
    if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      initAppleID()
      document.addEventListener('AppleIDSignInOnSuccess', this.onAppleIdSuccess)
      document.addEventListener('AppleIDSignInOnFailure', this.onAppleIdFailure)
    }
  }

  componentWillUnmount() {
    if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      document.removeEventListener('AppleIDSignInOnSuccess', this.onAppleIdSuccess)
      document.removeEventListener('AppleIDSignInOnFailure', this.onAppleIdFailure)
    }
  }
  appleIdSuccess(event) {
    this.props.appleLogin(event.detail)
  }
  appleIdFailure(error) {
    // TODO
    if(error.detail && error.detail.error == 'popup_closed_by_user') {
      // ignore popup closed
    }
    else {
      alert('Error signing in with Apple')
    }
  }
  nativeAppleLogin() {
    window.spcoAppleLogin(this.onAppleIdSuccess);
  }
  responseFacebook(response) {
    this.props.facebookLogin(response)
  }
  render() {
    if(this.props.signedIn) {
      // Native app handles sign-in by removing it from the nav stack
      if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      // Somehow, the reduxForm wrapper adds history to the props
        this.props.history.push(`/compositions`)
      }
      return false
    }
    setTitle('Sign in')

    let appleLogin
    if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
      appleLogin = <button type="button" className="button strong apple_signin" onClick={this.onNativeAppleLoginClick}><SvgIcon icon={appleIcon} alt="Apple" height="20" width="20" fill="#ffffff" /> Sign in with Apple</button>
    }
    else if(process.env.REACT_APP_ANDROID) {
      appleLogin = null
    }
    else {
      appleLogin = <button className="button strong apple_signin" onClick={() => window.AppleID.auth.signIn()}><SvgIcon icon={appleIcon} alt="Apple" height="20" width="20" fill="#ffffff" /> Sign in with Apple</button>
    }

    return (
      <SessionHeader>

        <form onSubmit={this.props.handleSubmit} className="hide_labels">
      <p className="extra_links text-center login_note">Enter your email; we’ll send you a link to sign in.</p>
        <Field name="email" label="Email" type="email" component={InputField} required="true" validate={[ required, email ]} />

        {this.props.error && <div className="callout alert"><p>{this.props.error}</p></div>}
        <div className="submit_section">
          <button type="submit" className="button large rounded strong" disabled={this.props.pristine || this.props.error || this.props.submitting}>
          Log In
        </button>
        </div>
      </form>

          <OrLine />

          <div className="apple_login">{appleLogin}</div>

      </SessionHeader>
    )

  }
}


SignIn.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  signedIn: PropTypes.bool,
  history: PropTypes.object,
  facebookLogin: PropTypes.func,
  appleLogin: PropTypes.func
}

// Decorate the form component
export default reduxForm({
  form: 'SignIn' // a unique name for this form
})(SignIn)

