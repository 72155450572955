import { setStorage, getStorage } from '../util'
import { handleErrors } from './fetch_handlers'

/* Generalized FetchMediaType
============================= */

// strings match API URLs
export const MEDIA_TYPE_LIVE_STREAM = 'live_streams'
export const MEDIA_TYPE_MEDIA_FEATURE = 'media_features'
export const MEDIA_TYPE_CURATED_COLLECTION = 'curated_collections'
export const MEDIA_TYPE_HOME = 'home_content'

export function fetchHomeDataIfNeeded() {
  return fetchMediaTypeIfNeeded(MEDIA_TYPE_HOME)
}

export function fetchLiveStreamsIfNeeded() {
  return fetchMediaTypeIfNeeded(MEDIA_TYPE_LIVE_STREAM)
}

export function fetchMediaFeaturesIfNeeded() {
  return fetchMediaTypeIfNeeded(MEDIA_TYPE_MEDIA_FEATURE)
}
export function fetchCuratedCollectionsIfNeeded() {
  return fetchMediaTypeIfNeeded(MEDIA_TYPE_CURATED_COLLECTION)
}

function fetchMediaTypeIfNeeded(kind) {
  return (dispatch, getState) => {
    if(shouldFetchMediaType(getState(), kind)) {
      return dispatch(fetchMediaType(kind))
    } else {
      return Promise.resolve()
    }
  }
}

function shouldFetchMediaType(state, type) {
  const data = state.data
  if(data.isFetchingMediaType[type]) {
    return false
  }
  else if (data.fetchedMediaType[type]) {
    return false
  } else {
    return true
  }
}

const STORAGE_KEY_MEDIA_TYPE = 'media-'
const STORAGE_KEY_MEDIA_TYPE_TIMESTAMP = 'media-timestamp'

function fetchMediaType(kind) {
  const storedDate = getStorage(`${STORAGE_KEY_MEDIA_TYPE_TIMESTAMP}-${kind}`)
  if(storedDate && storedDate > Date.now() - 1000 * 60 * 15 && getStorage(`${STORAGE_KEY_MEDIA_TYPE}-${kind}`)) { // 15 minutes
    return fetchLocalMediaType(kind)
  }
  else {
    return fetchRemoteMediaType(kind)
  }
}

function fetchLocalMediaType(kind) {
  return dispatch => {
    const json = getStorage(`${STORAGE_KEY_MEDIA_TYPE}-${kind}`)
    return new Promise( (resolve, reject) => {
      dispatch(receiveMediaType(json, kind))
      return resolve()
    })
  }
}

function fetchRemoteMediaType(kind) {
  return dispatch => {
    dispatch(fetchingMediaType(kind))
    return fetch(`${process.env.REACT_APP_API_BASE}/${kind}/?format=json`)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        // sanity check
        if((json.results && json.results.length > 0) || json.length > 0) { storeFetchedData(json, kind) }
        return json
      })
      .then(json => dispatch(receiveMediaType(json, kind)))
      .catch(error => {
        if(process.env.NODE_ENV === 'production') {
          window.Rollbar.error(`Problem fetching media ${kind}`, error)
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
        else {
          throw error
        }
      })
  }
}

function storeFetchedData(json, kind) {
  setStorage(`${STORAGE_KEY_MEDIA_TYPE}-${kind}`, json)
  setStorage(`${STORAGE_KEY_MEDIA_TYPE_TIMESTAMP}-${kind}`, Date.now())
}

export const FETCHING_MEDIA_TYPE = 'FETCHING_MEDIA_TYPE'
function fetchingMediaType(kind) {
  return {
    type: FETCHING_MEDIA_TYPE,
    kind: kind
  }
}

// Receiving is the only function where we do custom
// actions based on the type
export const RECEIVE_MEDIA_TYPE = 'RECEIVE_MEDIA_TYPE'
function receiveMediaType(json, kind) {
  return dispatch => {
    dispatch({
      type: RECEIVE_MEDIA_TYPE,
      kind: kind,
      data: json,
      receivedAt: Date.now()
    })
    if(kind === MEDIA_TYPE_LIVE_STREAM) {
      dispatch(startLiveStreamTimer(json))
    }
  }
}

export const CHECK_LIVE_STREAMS = 'CHECK_LIVE_STREAMS'
function startLiveStreamTimer(json) {
  return dispatch => {
    if(json.results.length > 0) {
      dispatch({
        type: 'START_TIMER',
        payload: {
          timerAction: CHECK_LIVE_STREAMS,
          timerName: CHECK_LIVE_STREAMS,
          timerInterval: 30000
        }
      })
    }
  }
}
