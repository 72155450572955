import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sesameSignInAction } from '../actions/user'
import PropTypes from 'prop-types'


class TokenLogin extends Component {

  componentDidMount() {
    this.props.loginWithToken()
  }

  render () {
    return <div />
  }
}

TokenLogin.propTypes = {
  loginWithToken: PropTypes.func
}


const mapStateToProps = (state, ownProps) => ({
  }
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginWithToken: () => {
      dispatch(sesameSignInAction(ownProps.match.params.token))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TokenLogin)
