/* eslint react/jsx-no-target-blank: 0 */

import "@babel/polyfill"
import React from 'react'
import { render } from 'react-dom'
import {Router, Redirect} from 'react-router'
import {Route} from 'react-router-dom'
import history from './history'

import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk/es'
import {timerMiddleware} from './middleware/timer'
import {middleware as flashMiddleware} from 'redux-flash'
import ScrollMemory from 'react-router-scroll-memory';
import reducer from './reducers'
import { fetchCombinedIfNeeded } from './actions/fetch_combined'
import { loadCurrentUser, setLocationHistory } from './actions/user'
import { loadCurrentViewBy } from './actions/compositions'
import { setOgUrl } from './util'

import Home from './containers/HomeContainer'
import CategoryCompositions from './containers/CategoryCompositionsContainer'
import Composition from './containers/CompositionContainer'
import Compositions from './containers/CompositionsContainer'
import EmailConfirm from './containers/EmailConfirmContainer'
import ForgotPassword from './containers/ForgotPasswordContainer'
import DeleteUser from './containers/DeleteUserContainer'
import LiveStream from './containers/LiveStreamContainer'
import LiveStreams from './containers/LiveStreamsContainer'
import MediaFeature from './containers/MediaFeatureContainer'
import MediaFeatures from './containers/MediaFeaturesContainer'
import CuratedCollections from './containers/CuratedCollectionsContainer'
import CuratedCollection from './containers/CuratedCollectionContainer'
import PlayerContainer from './containers/PlayerContainer'
import Program from './containers/ProgramContainer'
import Programs from './containers/ProgramsContainer'
import Registration from './containers/RegistrationContainer'
import ResetPassword from './containers/ResetPasswordContainer'
import TokenLogin from './containers/TokenLoginContainer'
import SignIn from './containers/SignInContainer'
import ContributeFooter from './components/ContributeFooter'

const store = createStore(reducer,
  applyMiddleware(
    thunkMiddleware,
    flashMiddleware(),
    timerMiddleware
  )
)

// Basic initialization
store.dispatch(fetchCombinedIfNeeded())
store.dispatch(loadCurrentUser())
store.dispatch(loadCurrentViewBy())
store.dispatch(setLocationHistory(process.env.REACT_APP_BASEPATH + location.pathname))
setOgUrl()

require('../scss/app.scss')

history.listen( (location) => {
  store.dispatch(setLocationHistory(process.env.REACT_APP_BASEPATH + location.pathname))
  setOgUrl()
})

if(process.env.REACT_APP_USE_HASH_HISTORY === "1") {
  window.spcoHistory = history
}

// Prevent signed-on users accessing sign-in
const NoUserRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    ! store.getState().user.currentUser
      ? <Component {...props} />
      : <Redirect to='/compositions' />
  )} />
)
NoUserRoute.propTypes = {
    component: PropTypes.func
}

render((
  <div>
    <Provider store={store}>
      <Router history={history}>
      <div>
        <ScrollMemory />
        <PlayerContainer />
        <Route exact path="/" component={Home} />

        <Route exact path="/compositions" component={Compositions} />
        <Route path="/compositions/by/:viewBy" component={Compositions} />
        <Route path="/compositions/composer/:categoryIdentifier" component={CategoryCompositions} params={{viewBy: 'composer'}} />
        <Route path="/compositions/performer/:categoryIdentifier" component={CategoryCompositions} params={{viewBy: 'performer'}}/>
        <Route path="/compositions/conductor/:categoryIdentifier" component={CategoryCompositions} params={{viewBy: 'conductor'}}/>
        <Route path="/compositions/genre/:categoryIdentifier" component={CategoryCompositions} params={{viewBy: 'genre'}}/>
        <Route path="/compositions/nationality/:categoryIdentifier" component={CategoryCompositions} params={{viewBy: 'genre'}}/>

        <Route path="/composition/:identifier" component={Composition} />

        <Route exact path="/concerts" component={Programs} />
        <Route path="/concerts/:viewBy" component={Programs} />
        <Route path="/concert/:identifier" component={Program} />

        <Route path="/live-streams" component={LiveStreams} />
        <Route path="/live-stream/:identifier" component={LiveStream} />

        <Route exact path="/features" component={MediaFeatures} />
        <Route exact path="/collections" component={CuratedCollections} />
        <Route exact path="/collections/:identifier" component={CuratedCollection} />
        <Route path="/features/:viewBy/:subcategory" component={MediaFeatures} />
        <Route exact path="/features/:viewBy" component={MediaFeatures} />
        <Route path="/feature/:identifier" component={MediaFeature} />

        <NoUserRoute path="/sign-in" component={SignIn} />
        <NoUserRoute path="/sign-up" component={Registration} />
        <Route path="/email-confirm/:key" component={EmailConfirm} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/account" component={DeleteUser} />
        <Route path="/magic/:token" component={TokenLogin} />
        <Route path="/reset-password/:uid/:token" component={ResetPassword} />
        <Route  render={({ location }) => location.pathname != '/'
            ? <ContributeFooter/>
            : null
        } />

      </div>
      </Router>
    </Provider>
    <div id="footer" className="row">
      <div className="columns">
  &copy; {new Date().getFullYear()} <a href="https://www.thespco.org/">The Saint Paul Chamber Orchestra</a>. All rights reserved.
  <a className="link" href="https://www.thespco.org/concert-library-help/" target="_blank">Concert Library Help</a> &middot;
  <a className="link" href="https://www.thespco.org/about-us/privacy-policy/">Privacy Policy</a> &middot;
  <a className="link" href="https://www.thespco.org/about-us/contact-us/">Contact us &rsaquo;</a>
      </div>
    </div>
  </div>
), document.getElementById('app'))
