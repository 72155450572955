import { connect } from 'react-redux'
import { sesameRequestAction, facebookLogin, appleLogin } from '../actions/user'
import SignIn from '../components/user/SignIn'

const mapStateToProps = (state, ownProps) => ({
  signedIn: !! state.user.currentUser
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (userInfo) => {
      return dispatch(sesameRequestAction(userInfo))
    },
    facebookLogin: (userInfo) => {
      return dispatch(facebookLogin(userInfo))
    },
    appleLogin: (userInfo) => {
      return dispatch(appleLogin(userInfo))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
