import {
  COMPOSITIONS_VIEW_TABLE,
  COMPOSITIONS_VIEW_GRID
} from '../actions/compositions'

const compositions = (state = {
  viewAsTable: true,
  }, action) => {
  switch (action.type) {
    case COMPOSITIONS_VIEW_TABLE:
      return Object.assign({}, state, {
        viewAsTable: true
      })
    case COMPOSITIONS_VIEW_GRID:
      return Object.assign({}, state, {
        viewAsTable: false
      })
    default:
      return state
  }
}

export default compositions
